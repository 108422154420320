<template>
	<div style="padding: 24rem;box-sizing: border-box">
		<el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="100rem">
			<el-form-item label="组合名称" prop="name">
				<el-input v-model="form.name" maxlength="20" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="总金额">
				{{ total.toFixed(2) }}
			</el-form-item>

			<el-form-item v-for="(i,index) in form.fees" :key="index" label-width="0">
				<div style="width: 100%;height: 88rem;background: #F6F9FF;border-radius: 4rem;display: flex;align-items: center;padding: 0 92rem 0 24rem">
					<el-form-item label="科目类型" label-width="104rem">
						<el-select v-model="i.aaa" style="width: 220rem" @change="onChange(i,index)">
							<el-option v-for="i in typeList" :label="i.name" :value="i.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="科目名称" label-width="104rem" style="margin: 0 80rem">
						<el-select v-model="i.fees" style="width: 220rem" @change="onFees(i,index)">
							<el-option v-for="i in nameList[index]" :label="i.name" :value="i.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="金额" label-width="72rem">
						{{ i.ccc || "0.00" }}
					</el-form-item>
					<div style="flex: 1;display: flex;justify-content:flex-end;">
						<el-button type="primary" @click="onAdd">添加</el-button>
						<el-button plain style="border-color: #326FFF; color: #326FFF" @click="onDelete(index)">删除</el-button>
					</div>
				</div>
			</el-form-item>
		</el-form>
		<div>
			<el-button :loading="loading" type="primary" @click="submit">提交</el-button>
			<el-button :disabled="loading" @click="$router.back()">返回</el-button>
		</div>
	</div>
</template>

<script>

export default {
	_config: {"route": {"path": "add", "meta": {"title": "新增"}}},
	data() {
		return {
			loading: false,
			form: {
				name: null,
				fees: [{
					aaa: null,
					fees: null,
					ccc: null,
				}],
			},
			rules: {
				name: [
					{required: true, message: "请输入组合名称", trigger: "blur"},
				],
			},
			typeList: [],
			nameList: [],
		};
	},
	mounted() {
		this.$_register.get("api/recruit/common/fee-type").then(res => {
			this.typeList = res.data.data;
		});
	},
	computed: {
		total: function () {
			return this.form.fees.reduce((a, b) => Number(a) + Number((b.ccc || 0)), 0);
		},
	},
	methods: {
		onFees(i, index) {
			this.form.fees[index].ccc = this.nameList[index].find(item => item.id === i.fees).price;
			this.$forceUpdate();
		},
		onChange(i, index) {
			this.nameList[index] = [];
			this.form.fees[index].ccc = null;
			this.form.fees[index].fees = null;
			this.$_register.get("api/finance/combination/get-fees?type=" + i.aaa).then(res => {
				this.nameList[index] = res.data.data;
				this.$forceUpdate();
			});
		},
		onAdd() {
			if (this.form.fees.length >= 30) return this.$message.error("最多添加30个收费科目");
			this.form.fees.push({
				aaa: null,
				fees: null,
				ccc: null,
			});
			this.nameList.push([]);
			this.$forceUpdate();
		},
		onDelete(index) {
			if (this.form.fees.length === 1) return this.$message.error("请添加收费科目");
			this.form.fees.splice(index, 1);
			this.nameList.splice(index, 1);
			this.$forceUpdate();
		},
		submit() {
			this.$refs.form.validate(res => {
				if (!res) return;
				for (let i = 0; i < this.form.fees.length; i++) {
					const item = this.form.fees[i];
					if (!item.aaa || !item.fees) {
						return this.$message.error("请填写所有科目类型和科目名称");
					}
				}
				this.loading = true;
				this.$_register.post("api/finance/combination/store", {name: this.form.name, fees: this.form.fees.map(i => i.fees).join()}).then(res => {
					this.$router.back();
				}).catch(() => this.loading = false);
			});
		},
	},
};
</script>
